import React from 'react'
import styled from "@emotion/styled"
import theme from '../shared/theme'

const SeparatorLine = () => {

    return(
        <>
            <SeparatorContainer />
        </>
    )
}

const SeparatorContainer = styled.div`
  width: 90%;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  padding-top: ${theme.spaces.standardBlockTopEnd}
`;

export default SeparatorLine;